import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { FlexColumn, FlexRow, Modal, Typography, UserList, Button } from "../../../components";
import { AutoCompleteTagsInput } from "../../../components/AutoCompleteTagsInput";
import { AVATAR_COLORS, COLORS } from "../../../constants";
import { AppState } from "../../../store";
import { BContext } from "../../../types";
import {
  getActiveOtherUsersInDomain,
  getPricingSummary,
  isValidEmail,
  planNameFromType,
  pluralize,
  useTrack,
} from "../../../utils";
import { BillingContext } from "../Billing";

import { PriceSummaryProratedV2 } from "./PriceSummary";

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 0px;
  }
`;

const Content = styled(FlexColumn)`
  && {
    min-height: 240px;
    flex: 1;
    line-height: 1.3;
  }
  #tags-input-container {
    min-height: 120px;
    flex-direction: row;
    display: flex;
    .tags {
      width: max-content;
      flex-direction: row;
    }
  }
`;

const PricingSummaryWrap = styled.div`
  background: ${COLORS.coeblue11};
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 14px;

  h3 {
    font-weight: bold;
    margin: 4px 0px 12px;
  }
`;

enum AddUserToPlanStep {
  SELECT = "SELECT",
  CHECKOUT = "CHECKOUT",
}

type Props = {
  open: boolean;
  onClose: () => void;
  initialUsersToAdd: Array<string>;
};

export const AddUserToPlanModal: React.FC<Props> = ({ open, onClose, initialUsersToAdd }) => {
  const track = useTrack();

  const {
    getUserSubscription,
    userSubscription,
    updateSubscription,
    billingMetadata,
    usersInCurrentPlan,
    otherUsersIndomain,
    setAdditionalSeats,
    currentUserPlanManager,
  } = useContext(BillingContext) as BContext;

  const { domains_in_domain_family } = useSelector((state: AppState) => ({
    domains_in_domain_family: state.app.domains_in_domain_family,
  }));

  const [tagInputKey, setTagInputKey] = useState<number>(1);

  const [usersToAdd, setUsersToAdd] = useState<Array<string>>(initialUsersToAdd);

  const usersToAddIdsSet = new Set(usersToAdd);

  const [query, setQuery] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [step, setStep] = useState<AddUserToPlanStep>(AddUserToPlanStep.SELECT);

  const subscriptionDetails = userSubscription?.subscription_details;

  const invitedUserIdSet = new Set(userSubscription?.invite_users?.map(u => u.user.email) ?? []);

  const usersInCurrentPlanIdSet = new Set(usersInCurrentPlan.map(u => u.user.email));

  const usersAlreadyOnAPlanIdSet = new Set(
    otherUsersIndomain.filter(u => u.managed_by_user !== undefined).map(u => u.user.email)
  );

  const maxUsers = subscriptionDetails?.max_users ?? 0;
  const currentExistingUsersCount = usersInCurrentPlan.length;
  const invitedUserCount = userSubscription?.invite_users?.length ?? 0;

  const availableSeatCount = maxUsers - currentExistingUsersCount - invitedUserCount;

  const validateDomainEmail = (email: string): string | undefined => {
    if (!isValidEmail(email)) {
      return "Please provide a valid email";
    }

    if (!domains_in_domain_family.includes(email.split("@")[1])) {
      return "Email address cannot be outside your domain";
    }

    if (invitedUserIdSet.has(email)) {
      return "This user has already been invited to your plan";
    }

    if (usersInCurrentPlanIdSet.has(email)) {
      return "This user is already on your plan";
    }

    if (usersAlreadyOnAPlanIdSet.has(email)) {
      return "This user is already on a plan";
    }

    return undefined;
  };

  const validUsersToAdd = usersToAdd.filter(email => validateDomainEmail(email) === undefined);
  const validUsersToAddCount = validUsersToAdd.length;

  const seatsToBuy = validUsersToAddCount - availableSeatCount;

  const requiresCheckout = seatsToBuy > 0;

  useEffect(() => {
    setIsLoading(false);
    setStep(AddUserToPlanStep.SELECT);
    setUsersToAdd([]);
    setQuery("");
    setAdditionalSeats(0);

    if (open) {
      // hard reset the tags input to clear errors on open
      setTagInputKey(key => key + 1);
      setUsersToAdd(initialUsersToAdd);
    }
  }, [open]);

  const usersInDomain = [...(billingMetadata?.users_from_domain ?? [])];

  const filteredUsersInDomain = usersInDomain.filter(u => {
    if (currentUserPlanManager?.email === u.user.email) {
      return false;
    }

    if (usersInCurrentPlanIdSet.has(u.user.email)) {
      return false;
    }

    if (usersToAddIdsSet.has(u.user.email)) {
      return false;
    }

    if (query === "") {
      return true;
    }

    return (
      u.user.email.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      (u.user.first_name ?? "").toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      (u.user.last_name ?? "").toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
  });

  const sortedOtherUsersInDomain = getActiveOtherUsersInDomain(filteredUsersInDomain);

  const planType = subscriptionDetails?.plan_type || "";

  const currentPlan = billingMetadata?.plans.find(
    plan =>
      plan.plan_type === planType && plan.billing_interval === subscriptionDetails?.billing_interval
  );

  const handleEmailChange = (tagList: string[]) => {
    setUsersToAdd(tagList);
  };

  const upgradeUsers = async () => {
    setIsLoading(true);
    const selectedUsers = usersInCurrentPlan.map(u => u.user?.user_id);

    const invitedUserEmails = (userSubscription?.invite_users ?? []).map(u => u.user?.email);

    validUsersToAdd.forEach(email => {
      const existingUser = otherUsersIndomain.find(u => u.user.email === email);

      if (existingUser) {
        selectedUsers.push(existingUser.user.user_id);
      } else {
        invitedUserEmails.push(email);
      }
    });

    const totalUserLiscenses = requiresCheckout ? maxUsers + seatsToBuy : maxUsers;

    await updateSubscriptionOnServer({
      plan: currentPlan,
      total_user_licenses: totalUserLiscenses,
      update_type: requiresCheckout ? "modify_subscription" : "reassignment",
      invite_user_emails: invitedUserEmails,
      selected_user_ids: selectedUsers,
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });
    track("billing_manage_team_user_added", {
      event_from: "user_list",
      added_users: otherUsersIndomain
        .filter(u => usersToAddIdsSet.has(u.user?.user_id!))
        .map(u => u.user?.email),
    });

    onClose();
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);
    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      message.success(`Your subscription has been updated`);
      getUserSubscription();
    }
  };

  const otherUsersInDomainEmails = sortedOtherUsersInDomain.map((user, i) => {
    const label = (
      <UserList
        key={i}
        className="dense"
        style={{ background: "inherit", minWidth: 450 }}
        avatarColor={AVATAR_COLORS[i % AVATAR_COLORS.length]}
        name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
        email={user.user?.email || ""}
        userId={user.user?.user_id || ""}
        isSelectable={false}
        plan={user.plan_type}
        selected={false}
        disabled={
          usersAlreadyOnAPlanIdSet.has(user.user.user_id!) ||
          usersInCurrentPlanIdSet.has(user.user.user_id!)
        }
        tooltip={
          usersAlreadyOnAPlanIdSet.has(user.user.user_id!)
            ? `User is already assigned to this plan`
            : user.managed_by_user && user.plan_type
            ? `User is already on ${planNameFromType(user.plan_type)} plan`
            : undefined
        }
        footer={
          <FlexRow style={{ justifyContent: "flex-end", marginLeft: "40px" }}>
            <Typography color="textSecondary" variant="body2">
              {user.managed_by_user
                ? `Plan managed by ${user.managed_by_user.first_name || ""} ${
                    user.managed_by_user.last_name || ""
                  }`
                : ""}
            </Typography>
          </FlexRow>
        }
      />
    );
    return { value: user.user.email, label };
  });

  const handleSelectSubmit = async () => {
    if (requiresCheckout) {
      setStep(AddUserToPlanStep.CHECKOUT);
      return;
    }

    setIsLoading(true);

    try {
      await upgradeUsers();
    } catch (err) {
      message.error("Uh-oh. Something went wrong. Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckoutBack = () => {
    setStep(AddUserToPlanStep.SELECT);
  };

  const handleCheckoutSubmit = async () => {
    setIsLoading(true);

    try {
      await upgradeUsers();
    } catch (err) {
      message.error("Uh-oh. Something went wrong. Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  const renderSelectStep = () => {
    const infoText = requiresCheckout
      ? `You have ${availableSeatCount} unassigned license${pluralize(
          availableSeatCount
        )}. You’ll need to purchase ${seatsToBuy} new user license${pluralize(
          seatsToBuy
        )} on the next step.`
      : "To invite new users, type or paste in emails above, separated by commas";

    const submitText = requiresCheckout ? "Next" : "Add Users";

    return (
      <Content>
        <AutoCompleteTagsInput
          autofocus
          key={tagInputKey}
          placeholder="Enter emails"
          onChange={tags => handleEmailChange(tags)}
          validator={validateDomainEmail}
          tags={usersToAdd}
          onSearch={val => setQuery(val)}
          options={otherUsersInDomainEmails}
        />
        <FlexColumn style={{ flex: 1, justifyContent: "space-between" }}>
          <Typography variant="body2" color="textSecondary" style={{ height: "40px" }}>
            {infoText}
          </Typography>

          <FlexRow style={{ justifyContent: "flex-end", marginTop: "auto" }}>
            <Button
              type="primary"
              style={{ minWidth: "120px" }}
              disabled={validUsersToAdd.length === 0 || isLoading}
              onClick={handleSelectSubmit}
            >
              {submitText}
            </Button>
          </FlexRow>
        </FlexColumn>
      </Content>
    );
  };

  const renderCheckoutStep = () => {
    // for computing new pricing summary
    const planMonthlyPrice: number =
      billingMetadata?.plans.find(
        p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "monthly"
      )?.per_user_plan?.per_item_monthly_price_usd || 0;

    const planAnnualMonthlyPrice: number =
      billingMetadata?.plans.find(
        p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "annually"
      )?.per_user_plan?.per_item_monthly_price_usd || 0;

    const numberOfUserInPlan: number = (subscriptionDetails?.max_users ?? 0) + seatsToBuy;

    setAdditionalSeats(seatsToBuy);

    const newPriceSummary = getPricingSummary(
      subscriptionDetails?.plan_type,
      subscriptionDetails?.billing_interval ?? "annually", // billing interval
      planMonthlyPrice, // monthly price for the plan (monthly interval)
      planAnnualMonthlyPrice, // monthly price for the plan (annual interval)
      currentPlan?.per_additional_data_source_addon?.per_item_monthly_price_usd || 0, // addtional data source cost
      numberOfUserInPlan, // # of users
      subscriptionDetails?.max_data_source_types ?? 0, // # of data souce
      billingMetadata?.coupon_discount_percentage
    );

    return (
      <Content>
        <Typography
          style={{ marginBottom: "24px" }}
          color="textSecondary"
          variant="body2"
        >{`${seatsToBuy} additional ${planNameFromType(planType)} user license${pluralize(
          seatsToBuy
        )} will be added to your plan`}</Typography>

        <PricingSummaryWrap>
          <PriceSummaryProratedV2 editType={"add-seats"} newPriceSummary={newPriceSummary} />
        </PricingSummaryWrap>
        <FlexRow style={{ justifyContent: "space-between", marginTop: "24px" }}>
          <Button
            type="text"
            disabled={usersToAdd.length === 0 || isLoading}
            onClick={handleCheckoutBack}
          >
            Back
          </Button>

          <Button
            type="primary"
            style={{ minWidth: "120px" }}
            disabled={usersToAdd.length === 0 || isLoading}
            onClick={handleCheckoutSubmit}
          >
            Confirm & Pay Now
          </Button>
        </FlexRow>
      </Content>
    );
  };

  const renderContent = () => {
    switch (step) {
      case AddUserToPlanStep.SELECT: {
        return renderSelectStep();
      }
      case AddUserToPlanStep.CHECKOUT: {
        return renderCheckoutStep();
      }
    }
  };

  return (
    <StyledModal
      title={
        step === "SELECT" ? (
          <Typography fontSize="22px" fontWeight={"bold"}>
            Add users to plan
          </Typography>
        ) : (
          <Typography fontSize="22px" fontWeight={"bold"}>
            Confirm plan changes
          </Typography>
        )
      }
      open={open}
      width={600}
      closable={true}
      onCancel={onClose}
    >
      {renderContent()}
    </StyledModal>
  );
};
