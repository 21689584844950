import { SubscriptionPlanType } from "@coeff/api";
import { message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { Alert, Button, DotsLoader } from "../../../components";
import { COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { planNameFromType, useTrack } from "../../../utils";
import { BillingContext } from "../Billing";
import { BillingForm, FAQSection, PriceSummary, PriceSummaryText } from "../components";

const Wrap = styled.div`
  color: ${COLORS.black85};
`;

const StyledAlert = styled(Alert)`
  border: none;
  margin-bottom: 10px;
`;

const InnerWrap = styled.div`
  display: flex;
`;

const Title = styled.h1`
  font-weight: bold;
  color: ${COLORS.black85};
  font-size: 24px;
  line-height: 1.3;

  a {
    font-size: 14px;
    font-weight: 500;
    color: ${COLORS.coeblue4};
  }
`;

const Box = styled.div`
  padding: 10px 15px;
  border-radius: 5px;
  position: relative;

  &.sticky {
    position: sticky;
    top: 0px;
    z-index: 10;
  }

  &.coeblue {
    background: ${COLORS.coeblue};
    margin-bottom: 1rem;
  }

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }

  .purchaseBtn {
    width: 100%;
    height: 44px;
    margin: 10px 0px;

    &:disabled {
      opacity: 0.5 !important;
    }
  }
`;

const Form = styled.div``;

export const Payment = () => {
  const track = useTrack();

  const {
    additionalSeats,
    billingMetadata,
    basePath,
    checkout: checkoutOnServer,
    getUserSubscription,
    invitedUserEmails,
    selectedBillingInterval,
    selectedDataSources,
    selectedPlanType,
    selectedUsers,
    setCheckoutSuccess,
  } = useContext(BillingContext) as BContext;

  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState<string>("");

  const [formError, setFormError] = useState<boolean>(false);

  const [name, setName] = useState<string>("");

  const [country, setCountry] = useState<string>("US");

  const [zip, setZip] = useState<string>("");

  const [processing, setProcessing] = useState<boolean>(false);

  const card: any = useRef();

  const stripe: any = useRef();

  useEffect(() => {
    if (!selectedPlanType) {
      return history.push({
        pathname: `${basePath}/plans`,
        search: window.location.search,
      });
    }
  }, []);

  const planType: SubscriptionPlanType = selectedPlanType || "pro";
  const planName = planNameFromType(planType);

  const onPurchase = () => {
    track("billing_payment_clicked", {
      selected_num_data_sources: selectedDataSources.length,
      selected_num_users: selectedUsers.length,
      selected_frequency: selectedBillingInterval,
      selected_plan_type: selectedPlanType,
    });
    setErrorMsg("");
    if (!name || !country || !zip) {
      return setFormError(true);
    }
    setProcessing(true);
    stripe.current
      .createToken(card.current, { name, address_country: country, address_zip: zip })
      .then(function (result) {
        if (result.error) {
          setErrorMsg(result.error.message);
          setProcessing(false);
          track("billing_payment_failed", {
            selected_num_data_sources: selectedDataSources.length,
            selected_num_users: selectedUsers.length,
            selected_frequency: selectedBillingInterval,
            selected_plan_type: selectedPlanType,
            reason: result.error.message,
          });
        } else {
          checkout(result.token.id);
        }
      });
  };

  const checkout = async cardToken => {
    const plan = billingMetadata?.plans.find(
      p => p.billing_interval === selectedBillingInterval && p.plan_type === selectedPlanType
    );

    const body = {
      plan,
      stripe_card_token: cardToken,
      selected_user_ids: selectedUsers.map(u => u.user_id),
      invite_user_emails: [...invitedUserEmails],
      selected_data_source_types: selectedDataSources,
      total_user_licenses: (selectedUsers.length >= 1 ? selectedUsers.length : 1) + additionalSeats,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    };

    const response = await checkoutOnServer(body);

    if (response.is_success) {
      message.success("Payment Successful");

      await getUserSubscription();

      window.opener?.postMessage(
        JSON.stringify({ type: "payment:success", plan_type: plan?.plan_type }),
        "*"
      );

      history.push({
        pathname: `${basePath}/subscription`,
        search: window.location.search,
      });
    } else {
      setErrorMsg(response.error_msg || "Uh-oh. Something went wrong. Please try again");

      track("billing_payment_failed", {
        selected_num_data_sources: selectedDataSources.length,
        selected_num_users: selectedUsers.length,
        selected_frequency: selectedBillingInterval,
        selected_plan_type: selectedPlanType,
        reason: response.error_msg,
      });
    }
    setProcessing(false);
  };

  return (
    <Wrap>
      <InnerWrap>
        <div style={{ width: "60%", maxWidth: "580px" }}>
          {errorMsg && (
            <StyledAlert
              type="error"
              showIcon={true}
              message={
                <>
                  <strong>Error processing payment.</strong> {errorMsg}
                </>
              }
            />
          )}

          <Title>
            <Link to={{ pathname: `${basePath}/customize`, search: window.location.search }}>
              Back
            </Link>
            <div>Checkout</div>
          </Title>

          <Form>
            <BillingForm
              card={card}
              stripe={stripe}
              formError={formError}
              name={name}
              setName={setName}
              country={country}
              setCountry={setCountry}
              zip={zip}
              setZip={setZip}
            />
          </Form>
        </div>

        <div style={{ width: "35%", maxWidth: "320px", margin: "0px auto" }}>
          <Box className="coeblue sticky" style={{ padding: "20px 30px" }}>
            <div className="sectionTitle">Your {planName} Plan</div>

            <PriceSummary />

            <Button
              className="purchaseBtn"
              type="primary"
              onClick={onPurchase}
              disabled={processing}
            >
              {processing ? <DotsLoader color="white" size="large" /> : "Purchase"}
            </Button>

            <PriceSummaryText />
          </Box>

          <FAQSection />
        </div>
      </InnerWrap>
    </Wrap>
  );
};
