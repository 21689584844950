import { SubscriptionPlanType, SubscriptionUser } from "@coeff/api";
import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "src/store";
import styled from "styled-components";

import {
  AutoCompleteTagsInput,
  Button,
  FlexColumn,
  FlexRow,
  Typography,
  UserList,
} from "../../../components";
import { AVATAR_COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { getActiveOtherUsersInDomain, isValidEmail, planNameFromType } from "../../../utils";
import { BillingContext } from "../Billing";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;

    .ant-modal-header {
      border-bottom: none;
      background: none;

      .ant-modal-title {
        font-size: 22px;
      }
    }

    .ant-modal-body {
      padding: 0px 24px 24px 24px;
      line-height: 1.3;
    }
  }

  #tags-input-container {
    min-height: 120px;
    flex-direction: row;
    display: flex;
    .tags {
      width: max-content;
      flex-direction: row;
    }
  }
`;

type Props = {
  open: boolean;
  close: () => void;
};

export const SeatSelectionCustomizerModal: React.FC<Props> = ({ open, close }) => {
  const {
    billingMetadata,
    additionalSeats,
    setAdditionalSeats,
    setUsers,
    selectedPlanType,
    selectedUsers,
    invitedUserEmails,
    setInvitedUserEmails,
  } = useContext(BillingContext) as BContext;

  const { domains_in_domain_family } = useSelector((state: AppState) => ({
    domains_in_domain_family: state.app.domains_in_domain_family,
  }));

  const planType: SubscriptionPlanType = selectedPlanType || "pro";

  const [tagInputKey, setTagInputKey] = useState<number>(1);

  useEffect(() => {
    setUsersToAdd([]);
    setQuery("");

    if (open) {
      // hard reset the tags input to clear errors on open
      setTagInputKey(key => key + 1);
    }
  }, [open]);

  const [usersToAdd, setUsersToAdd] = useState<Array<string>>([]);

  const usersToAddIdsSet = new Set(usersToAdd);

  const [query, setQuery] = useState("");

  const otherUsersInDomain = [...(billingMetadata?.users_from_domain ?? [])];

  const usersInCurrentPlanIdSet = new Set(selectedUsers.map(u => u.email));

  const filteredUsersInDomain = otherUsersInDomain.filter(u => {
    if (usersInCurrentPlanIdSet.has(u.user.email)) {
      return false;
    }

    if (usersToAddIdsSet.has(u.user.email)) {
      return false;
    }

    if (query === "") {
      return true;
    }

    return (
      u.user.email.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      (u.user.first_name ?? "").toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      (u.user.last_name ?? "").toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
  });

  const sortedOtherUsersInDomain = getActiveOtherUsersInDomain(filteredUsersInDomain);

  const usersAlreadyOnAPlanIdSet = new Set(
    otherUsersInDomain.filter(u => u.managed_by_user !== undefined).map(u => u.user.email)
  );

  const validateDomainEmail = (email: string): string | undefined => {
    if (!isValidEmail(email)) {
      return "Please provide a valid email";
    }

    if (!domains_in_domain_family.includes(email.split("@")[1])) {
      return "Email address cannot be outside your domain";
    }

    if (usersInCurrentPlanIdSet.has(email)) {
      return "This user is already on your plan";
    }

    if (usersAlreadyOnAPlanIdSet.has(email)) {
      return "This user is already on a plan";
    }

    return undefined;
  };

  const validUsersToAdd = usersToAdd.filter(email => validateDomainEmail(email) === undefined);
  const validUsersToAddCount = validUsersToAdd.length;

  const handleEmailChange = (tagList: string[]) => {
    setUsersToAdd(tagList);

    const hasError = tagList.some(tag => validateDomainEmail(tag));
  };

  const otherUsersInDomainEmails = sortedOtherUsersInDomain.map((user, i) => {
    const label = (
      <UserList
        key={i}
        className="dense"
        style={{ background: "inherit", minWidth: 450 }}
        avatarColor={AVATAR_COLORS[i % AVATAR_COLORS.length]}
        name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
        email={user.user?.email || ""}
        userId={user.user?.user_id || ""}
        isSelectable={false}
        plan={user.plan_type}
        selected={false}
        disabled={
          usersAlreadyOnAPlanIdSet.has(user.user.user_id!) ||
          usersInCurrentPlanIdSet.has(user.user.user_id!)
        }
        tooltip={
          usersAlreadyOnAPlanIdSet.has(user.user.user_id!)
            ? `User is already assigned to this plan`
            : user.managed_by_user && user.plan_type
            ? `User is already on ${planNameFromType(user.plan_type)} plan`
            : undefined
        }
        footer={
          <FlexRow style={{ justifyContent: "flex-end", marginLeft: "40px" }}>
            <Typography color="textSecondary" variant="body2">
              {user.managed_by_user
                ? `Plan managed by ${user.managed_by_user.first_name || ""} ${
                    user.managed_by_user.last_name || ""
                  }`
                : ""}
            </Typography>
          </FlexRow>
        }
      />
    );
    return { value: user.user.email, label };
  });

  const handleDone = () => {
    const newAdditionalSeats = Math.max(0, additionalSeats - validUsersToAddCount);

    setAdditionalSeats(newAdditionalSeats);

    const newSelectedUsers: Array<SubscriptionUser> = [];

    const newInvitedUserEmails: Array<string> = [];

    validUsersToAdd.forEach(email => {
      const existingUser = otherUsersInDomain.find(u => u.user.email === email);

      if (existingUser) {
        newSelectedUsers.push(existingUser.user);
      } else {
        newInvitedUserEmails.push(email);
      }
    });
    setInvitedUserEmails([...invitedUserEmails, ...newInvitedUserEmails]);
    setUsers([...selectedUsers, ...newSelectedUsers]);

    close();
  };

  return (
    <StyledModal
      width={600}
      title="Add User to plan"
      closable={true}
      visible={open}
      onCancel={close}
      footer={null}
    >
      <FlexColumn gap={1}>
        <AutoCompleteTagsInput
          autofocus
          key={tagInputKey}
          placeholder="Enter emails"
          onChange={tags => handleEmailChange(tags)}
          validator={validateDomainEmail}
          tags={usersToAdd}
          onSearch={val => setQuery(val)}
          options={otherUsersInDomainEmails}
        />

        <Typography variant="body2" color="textSecondary" style={{ height: "40px" }}>
          To invite new users, type or paste in emails above, separated by commas
        </Typography>
      </FlexColumn>

      <FlexRow style={{ justifyContent: "space-between", marginTop: "8px" }}>
        <Button type="link" onClick={() => close()} noPadding>
          Cancel
        </Button>
        <Button
          style={{ width: 120 }}
          disabled={validUsersToAdd.length === 0}
          type="primary"
          onClick={handleDone}
        >
          Done
        </Button>
      </FlexRow>
    </StyledModal>
  );
};
