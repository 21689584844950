import { UserOutlined } from "@ant-design/icons";
import { DataSourceType, FeatureFlag, SubscriptionPlanType, SubscriptionUser } from "@coeff/api";
import { Dropdown, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useGate } from "statsig-react";
import styled from "styled-components";

import {
  AddSeats,
  DEPRECATED_Button,
  ConfirmBox,
  message,
  Radio,
  Tag,
  UserList,
  DotsLoader,
  FlexColumn,
  FlexRow,
  Typography,
  Button,
  EllipsisMenu,
} from "../../../components";
import { AVATAR_COLORS as avatarColors, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import {
  getActiveOtherUsersInDomain,
  getIsGmailLikeDomainFromEmail,
  planNameFromType,
  pluralize,
  useTrack,
} from "../../../utils";
import { BillingContext } from "../Billing";
import { FAQSection, PriceSummary, PriceSummaryProrated, PriceSummaryText } from "../components";
import { SeatSelectionCustomizerModal } from "../components/SeatSelectionCustomizerModal";

const Container = styled.div`
  color: ${COLORS.black85};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PlanFeatures = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  maxwidth: 580px;
`;

const BillingFrequencySection = styled.div`
  padding: 10px 24px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
  background: white;

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

const FrequencyRadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  .ant-radio-wrapper {
    color: ${COLORS.black85};
    font-weight: 600;
    border: 2px solid ${COLORS.black15};
    width: 100%;
    padding: 12px;
    border-radius: 5px;

    &.ant-radio-wrapper-checked {
      color: ${COLORS.coeblue4};
      border-color: ${COLORS.coeblue4};
    }

    margin-right: 0px;
  }
`;

const UserSeatsSection = styled.div`
  padding: 10px 24px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
  background: ${COLORS.black4};

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

const PlanSummary = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  maxwidth: 320px;
  margin: 0px auto;
`;

const Title = styled.h1`
  font-weight: bold;
  color: ${COLORS.black85};
  font-size: 24px;

  a {
    font-size: 14px;
    font-weight: 500;
    color: ${COLORS.coeblue4};
    margin-left: 10px;
  }
`;

const Box = styled.div`
  padding: 10px 24px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
  background: ${COLORS.black2};

  &.white {
    background: white;
  }

  &.indented {
    padding: 10px 15px 10px 35px;
  }

  .sectionNumber {
    position: absolute;
    top: 12px;
    left: 0px;
  }

  &.coeblue {
    background: ${COLORS.coeblue};
    margin-bottom: 1rem;
  }

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }

  .ant-radio-wrapper {
    color: ${COLORS.black85};
    font-weight: 600;
    border: 2px solid ${COLORS.black15};
    width: 40%;
    padding: 12px;
    border-radius: 5px;

    &.ant-radio-wrapper-checked {
      color: ${COLORS.coeblue4};
      border-color: ${COLORS.coeblue4};
    }

    margin-right: 16px;
  }

  .checkoutBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    margin: 10px 0px;
  }

  .selectable {
    background: white;
    border-color: white;
  }

  .additionalUsers {
    .ant-avatar {
      font-size: 24px;
    }

    &:hover {
      border-color: ${COLORS.white};
    }
  }
`;

const StickyBox = styled.div`
  margin-right: 10px;
  position: sticky;
  top: 0px;
  z-index: 10;
`;

export const PlanCustomizerV2 = () => {
  const track = useTrack();

  const { value: enablePricingPageUpdates } = useGate(FeatureFlag.EnablePricingPageUpdates);

  const {
    additionalSeats,
    billingMetadata,
    basePath,
    currentUserEmail,
    priceSummary,
    selectedPlanType,
    setPlanType,
    selectedBillingInterval,
    selectedDataSources,
    selectedUsers,
    invitedUserEmails,
    setAdditionalSeats,
    setBillingInterval,
    setDataSources,
    setUsers,
    userSubscription,
    updateSubscription,
    getUserSubscription,
    setInvitedUserEmails,
  } = useContext(BillingContext) as BContext;

  const history = useHistory();

  useEffect(() => {
    document.title = "Customize your plan - Coefficient Workspace";
  }, []);

  const selectedUserIdSet = new Set(selectedUsers.map(u => u.user_id!));

  const otherUsersInDomain = (billingMetadata?.users_from_domain ?? []).filter(user => {
    return !selectedUserIdSet.has(user.user.user_id!);
  });

  const activeOtherUsersInDomain = getActiveOtherUsersInDomain(otherUsersInDomain);

  const planType: SubscriptionPlanType = selectedPlanType || "pro";

  const planName = planNameFromType(planType);

  const isGmailLikeDomain = getIsGmailLikeDomainFromEmail(currentUserEmail);

  // If showPlanChangeConfirm is a string, it represents the userId
  // that we want to add to the plan if the user confirms the change,
  // otherwise assume it's being added to additionalSeats
  const [showPlanChangeConfirm, setShowPlanChangeConfirm] = useState<boolean | string>(false);

  const [selectionModalOpen, setSelectionModalOpen] = useState<boolean>(false);

  const [upgradeLoading, setUpgradeLoading] = useState<boolean>(false);

  const planData = billingMetadata?.plans.find(
    p => p.plan_type === selectedPlanType && p.billing_interval === selectedBillingInterval
  );

  const subscriptionDetails = userSubscription?.subscription_details;

  useEffect(() => {
    if (!selectedPlanType) {
      return history.push({
        pathname: `${basePath}/plans`,
        search: window.location.search,
      });
    }

    // set default user
    const currentUser = billingMetadata?.users_from_domain.find(
      u => u.user?.email === currentUserEmail
    );

    if (currentUser?.user && selectedUsers.length === 0) {
      setUsers([currentUser.user]);
    }

    if (selectedDataSources.length === 0 && !enablePricingPageUpdates) {
      // set default data sources
      const domainDatasources: DataSourceType[] = [];

      billingMetadata?.all_data_source_types.forEach(ds => {
        if (ds.used_by_domain && ds.data_source_type) {
          domainDatasources.push(ds.data_source_type);
        }
      });

      setDataSources(domainDatasources.slice(0, 5));
    }
  }, []);

  let usersInDomain = [...(billingMetadata?.users_from_domain ?? [])];

  usersInDomain = usersInDomain.sort(function (a, b) {
    return (b.num_imports || 0) - (a.num_imports || 0);
  });

  const currentUserIndex = usersInDomain.findIndex(u => u.user?.email === currentUserEmail);

  if (currentUserIndex >= 0) {
    usersInDomain.splice(0, 0, usersInDomain.splice(currentUserIndex, 1)[0]);
  }

  const getPerUserDiscount = () => {
    const monthlyPrice = billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "monthly"
    )?.per_user_plan?.per_item_monthly_price_usd;
    const annualPrice = billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "annually"
    )?.per_user_plan?.per_item_monthly_price_usd;
    if (!monthlyPrice || !annualPrice) {
      return 0;
    }
    return monthlyPrice - annualPrice;
  };

  const onCheckout = () => {
    track("billing_checkout_clicked", {
      selected_num_data_sources: selectedDataSources.length,
      selected_num_users: selectedUsers.length,
      selected_frequency: selectedBillingInterval,
      selected_plan_type: selectedPlanType,
    });

    if (subscriptionDetails) {
      return upgradeToPro();
    }
    if (selectedPlanType === "starter" && selectedUsers.length + additionalSeats > 1) {
      return message.error("Starter plan is for individuals only. Please select only one user");
    }

    return history.push({
      pathname: `${basePath}/payment`,
      search: window.location.search,
    });
  };

  const upgradeToPro = async () => {
    setUpgradeLoading(true);
    const currentPlan = billingMetadata?.plans.find(
      plan =>
        plan.plan_type === "pro" && plan.billing_interval === subscriptionDetails?.billing_interval
    );

    await updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "modify_subscription",
      selected_user_ids: selectedUsers.map(u => u.user_id),
      selected_data_source_types: selectedDataSources,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });

    track("billing_plan_changed", {
      from_plan_type: "pro",
      to_plan_type: "starter",
    });

    history.push({
      pathname: `${basePath}/subscription`,
      search: window.location.search,
    });
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);
    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      message.success("Plan updated to Pro successfully");
      getUserSubscription();
    }
  };

  // Always default to at least 1 seat
  const totalSeats = selectedUsers.length + additionalSeats + invitedUserEmails.length;

  const handleRemoveUser = (email: string) => {
    const newSelectedUser = selectedUsers.filter(u => u.email !== email);
    const newInvitedUserEmails = invitedUserEmails.filter(e => e !== email);

    setUsers(newSelectedUser);
    setInvitedUserEmails(newInvitedUserEmails);

    setAdditionalSeats(additionalSeats + 1);
  };

  const renderUserMenu = (email: string) => {
    return (
      <Menu>
        <Menu.Item key={"remove-user"} onClick={() => handleRemoveUser(email)}>
          Remove user from plan
        </Menu.Item>
      </Menu>
    );
  };

  const renderAdditionalUsers = () => {
    if (additionalSeats === 0) {
      return <noscript />;
    }
    return (
      <UserList
        key={`additional-seat`}
        avatarIcon={<UserOutlined />}
        avatarColor={COLORS.black25}
        className="additionalUsers white"
        name={`${additionalSeats} Unassigned License${pluralize(additionalSeats)}`}
        email={"Invite and assign them later"}
        userId={""}
        isSelectable={false}
        selected={false}
        disabled={true}
        footer={
          <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
            <Button
              noPadding
              type="link"
              onClick={() => {
                if (planType === SubscriptionPlanType.Starter && selectedUsers.length === 1) {
                  setShowPlanChangeConfirm(true);
                  return;
                }
                setSelectionModalOpen(true);
              }}
            >
              {`Add user${pluralize(additionalSeats)} to plan`}
            </Button>
          </FlexRow>
        }
      />
    );
  };

  const renderSelectedUsers = () => {
    const selectedUserIds = selectedUsers.map(u => u.user_id as string);
    return selectedUserIds
      .map(userId => usersInDomain.find(u => u.user.user_id === userId))
      .map((user, i) => {
        if (user === undefined) {
          return <noscript />;
        }
        return (
          <UserList
            className="white"
            key={i}
            avatarColor={avatarColors[i % avatarColors.length]}
            name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
            email={user.user?.email || ""}
            userId={user.user?.user_id || ""}
            isSelectable={false}
            plan={user.plan_type}
            disabled={true}
            footer={
              <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                <FlexRow gap={2} style={{ justifyContent: "space-between" }}>
                  <Typography color="textSecondary" fontWeight={500}>
                    {user.user.email === currentUserEmail ? `Plan Owner` : ``}
                  </Typography>

                  <Dropdown overlay={renderUserMenu(user.user?.email)} placement="bottomLeft">
                    <Button type="text" size="small">
                      <EllipsisMenu />
                    </Button>
                  </Dropdown>
                </FlexRow>
              </FlexRow>
            }
          />
        );
      });
  };

  const renderInvitedUsers = () => {
    return invitedUserEmails.map((email, i) => {
      return (
        <UserList
          className="white"
          key={i}
          avatarColor={avatarColors[i % avatarColors.length]}
          name={email}
          email={email}
          userId={email}
          isSelectable={false}
          plan={undefined}
          disabled={true}
          footer={
            <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
              <FlexRow gap={2} style={{ justifyContent: "space-between" }}>
                <Typography color="textSecondary" fontWeight={500}>
                  Invited
                </Typography>

                <Dropdown overlay={renderUserMenu(email)} placement="bottomLeft">
                  <Button type="text" size="small">
                    <EllipsisMenu />
                  </Button>
                </Dropdown>
              </FlexRow>
            </FlexRow>
          }
        />
      );
    });
  };

  return (
    <Container>
      <Content>
        <PlanFeatures>
          <Title>
            {planName} Plan{" "}
            <Link to={{ pathname: `${basePath}/plans`, search: window.location.search }}>
              View plans &amp; features
            </Link>
          </Title>

          <BillingFrequencySection>
            <div className="sectionTitle" style={{ marginBottom: "15px" }}>
              Billing Frequency
            </div>
            <FrequencyRadioWrapper>
              {(!subscriptionDetails || subscriptionDetails.billing_interval === "monthly") && (
                <Radio
                  checked={selectedBillingInterval === "monthly"}
                  onClick={() => setBillingInterval("monthly")}
                >
                  Monthly
                </Radio>
              )}
              <Radio
                checked={selectedBillingInterval === "annually"}
                onClick={() => setBillingInterval("annually")}
              >
                Annual &nbsp;
                <Tag color={"#DEF1D5"} style={{ color: "#44AD10" }}>
                  Save 17%
                </Tag>
              </Radio>
            </FrequencyRadioWrapper>
          </BillingFrequencySection>

          <UserSeatsSection>
            <FlexRow
              style={{
                justifyContent: "space-between",
                marginBottom: "16px",
                alignItems: "flex-start",
              }}
            >
              <FlexColumn gap={0}>
                <div className="sectionTitle">{`${totalSeats} User License${pluralize(
                  totalSeats
                )}`}</div>
                <Typography color="textSecondary">{`$${planData?.per_user_plan?.per_item_monthly_price_usd} / user / month`}</Typography>
                {selectedBillingInterval === "annually" && (
                  <Typography color="textSecondary">{`(with $${getPerUserDiscount()} annual plan savings)`}</Typography>
                )}
              </FlexColumn>

              <FlexColumn style={{ alignItems: "flex-end" }}>
                {!isGmailLikeDomain && (
                  <AddSeats
                    style={{ marginTop: "16px" }}
                    onAddUser={() => {
                      // Do not allow more than 1 user for starter plan
                      if (
                        (additionalSeats >= 1 ||
                          (additionalSeats === 0 && selectedUsers.length > 0)) &&
                        planType === "starter"
                      ) {
                        return setShowPlanChangeConfirm(true);
                      }

                      setAdditionalSeats(additionalSeats + 1);
                    }}
                    onRemoveUser={() => {
                      if (totalSeats === 1) {
                        return;
                      }

                      if (additionalSeats > 0) {
                        setAdditionalSeats(additionalSeats - 1);
                      } else {
                        setUsers([...selectedUsers.slice(0, -1)]);
                      }
                    }}
                    userCount={totalSeats}
                  />
                )}
              </FlexColumn>
            </FlexRow>

            <div>
              {renderSelectedUsers()}
              {renderInvitedUsers()}

              {renderAdditionalUsers()}
            </div>
          </UserSeatsSection>

          {!isGmailLikeDomain && activeOtherUsersInDomain.length > 0 && (
            <FlexColumn gap={0}>
              <Typography fontSize="20px" style={{ marginBottom: "5px" }} fontWeight={600}>
                Other users from your domain
              </Typography>

              {activeOtherUsersInDomain.map((user, i) => (
                <UserList
                  key={i}
                  avatarColor={
                    avatarColors[
                      (i + selectedUsers.length + invitedUserEmails.length) % avatarColors.length
                    ]
                  }
                  name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                  email={user.user?.email || ""}
                  userId={user.user?.user_id || ""}
                  plan={user.plan_type}
                  footer={
                    <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                      {user.managed_by_user ? (
                        `Plan managed by ${user.managed_by_user.first_name || ""} ${
                          user.managed_by_user.last_name || ""
                        }`
                      ) : (
                        <Button
                          type="link"
                          onClick={() => {
                            if (
                              planType === SubscriptionPlanType.Starter &&
                              selectedUsers.length === 1
                            ) {
                              setShowPlanChangeConfirm(true);
                              return;
                            }
                            const newAdditionalSeats = Math.max(0, additionalSeats - 1);
                            setAdditionalSeats(newAdditionalSeats);
                            setUsers([...selectedUsers, user.user]);
                          }}
                        >
                          Add user to plan
                        </Button>
                      )}
                    </FlexRow>
                  }
                />
              ))}
            </FlexColumn>
          )}
        </PlanFeatures>

        <PlanSummary>
          <StickyBox>
            <Box className="coeblue" style={{ padding: "20px 30px" }}>
              <div className="sectionTitle">Plan Summary</div>
              {subscriptionDetails ? (
                <PriceSummaryProrated editType="upgrade-to-pro" newPriceSummary={priceSummary} />
              ) : (
                <PriceSummary />
              )}

              <DEPRECATED_Button
                disabled={upgradeLoading}
                className="checkoutBtn"
                type="primary"
                onClick={onCheckout}
              >
                {upgradeLoading ? <DotsLoader color="white" size="large" /> : "Checkout"}
              </DEPRECATED_Button>

              {!subscriptionDetails && <PriceSummaryText />}
            </Box>

            <FAQSection />
          </StickyBox>
        </PlanSummary>
      </Content>

      <SeatSelectionCustomizerModal
        open={selectionModalOpen}
        close={() => setSelectionModalOpen(false)}
      />

      <ConfirmBox
        visible={Boolean(showPlanChangeConfirm)}
        title="Change plan to Pro?"
        cancelText="Cancel"
        okText="Change to Pro"
        onCancel={() => setShowPlanChangeConfirm(false)}
        onOk={() => {
          setPlanType("pro");

          if (typeof showPlanChangeConfirm === "string") {
            const selectedUser = billingMetadata?.users_from_domain.find(
              u => u.user?.user_id === showPlanChangeConfirm
            )?.user;

            if (selectedUser) {
              setUsers([...selectedUsers, selectedUser]);
            }
          } else {
            setAdditionalSeats(additionalSeats + 1);
          }

          setShowPlanChangeConfirm(false);
        }}
        width={400}
      >
        Adding additional users requires a Pro plan. Do you want to change your plan to Pro?
      </ConfirmBox>
    </Container>
  );
};
