import { SubscriptionUser } from "@coeff/api";
import { Dropdown, Menu, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  AddSeats,
  Breadcrumb,
  BreadcrumbItem,
  DEPRECATED_Button,
  ConfirmBox,
  DEPRECATED_InputTextarea,
  UserList,
} from "../../../components";
import { AVATAR_COLORS as avatarColors, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { getPricingSummary, planNameFromType, pluralize, useTrack } from "../../../utils";
import { BillingContext } from "../Billing";
import { FAQSection, PriceSummaryProrated } from "../components";

const Wrap = styled.div`
  color: ${COLORS.black85};

  .mainTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
`;

const HelpSubscription = styled.div`
  font-weight: bold;
  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-underline-thickness: 1px;
    cursor: pointer;
  }
`;

const InnerWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Section = styled.div`
  margin-bottom: 20px;

  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }

  .assignUsers {
    .ant-checkbox-wrapper {
      display: none;
    }

    .email {
      color: ${COLORS.coeblue4};
    }

    &:hover {
      border-color: ${COLORS.black2};
    }
  }
`;

const TitleSection = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    .ant-btn {
      font-weight: bold;
    }
  }
`;

const PricingSummaryWrap = styled.div`
  background: ${COLORS.coeblue};
  padding: 8px 24px;
  border-radius: 8px;
  width: 320px;
  margin: auto;
  font-size: 14px;

  h3 {
    font-weight: bold;
    margin: 4px 0px 12px;
  }
`;

const EmailValidationRegex =
  /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}(?:,[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})*$/;

export const ManageTeam = () => {
  const track = useTrack();

  const {
    basePath,
    getUserSubscription,
    enableSubscriptionUpdate,
    userSubscription,
    updateSubscription,
    billingMetadata,
    additionalSeats,
    setAdditionalSeats,
    setContactSupportInfoBox,
    setPlanType,
    setBillingInterval,
    setDataSources,
    usersInCurrentPlan,
    otherUsersIndomain,
  } = useContext(BillingContext) as BContext;

  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
  const [inviteEmails, setInviteEmails] = useState<string>("");
  const isValidEmails = EmailValidationRegex.test(inviteEmails) || inviteEmails === "";

  const [downgradeUserConfirm, setDowngradeUserConfirm] = useState<SubscriptionUser | null>(null);

  const [showEditSeatsPanel, setShowEditSeatsPanel] = useState<null | "add" | "remove">(null);
  const [showAddUserConfirm, setShowAddUserConfirm] = useState<boolean>(false);

  const [updateSeatsLoading, setUpdateSeatsLoading] = useState<boolean>(false);

  const subscriptionDetails = userSubscription?.subscription_details;
  const planType = subscriptionDetails?.plan_type || "";

  const currentPlan = billingMetadata?.plans.find(
    plan =>
      plan.plan_type === planType && plan.billing_interval === subscriptionDetails?.billing_interval
  );

  const downgradeUser = userId => {
    const selectedUsers = usersInCurrentPlan
      .filter(u => u.user?.user_id !== userId)
      .map(u => u.user?.user_id);

    updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      selected_user_ids: selectedUsers,
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });

    track("billing_manage_team_user_removed", {
      removed_user: usersInCurrentPlan.find(u => userId === u.user?.user_id)?.user?.email,
    });
  };

  const upgradeUser = userId => {
    const selectedUsers = usersInCurrentPlan.map(u => u.user?.user_id);

    selectedUsers.push(userId);

    updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      invite_user_emails: (userSubscription?.invite_users ?? []).map(u => u.user?.email),
      selected_user_ids: selectedUsers,
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });
    track("billing_manage_team_user_added", {
      event_from: "user_list",
      added_users: otherUsersIndomain
        .filter(u => userId === u.user?.user_id)
        .map(u => u.user?.email),
    });
  };

  const inviteUsers = () => {
    if (inviteEmails && isValidEmails) {
      const invite_emails = [
        ...(userSubscription?.invite_users ?? []).map(u => u.user?.email),
        ...inviteEmails.split(",").map(e => e.trim()),
      ];
      updateSubscriptionOnServer({
        plan: currentPlan,
        update_type: "reassignment",
        invite_user_emails: [...new Set(invite_emails)],
        selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
        selected_data_source_types: subscriptionDetails?.selected_data_source_types,
        coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
      });
      track("billing_manage_team_user_added", {
        event_from: "invite_user_popup",
        added_users: inviteEmails.split(",").map(e => e.trim()),
      });
    }
  };

  const removeInvitedUser = email => {
    updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      invite_user_emails: (userSubscription?.invite_users ?? [])
        .filter(u => u.user?.email !== email)
        .map(u => u.user?.email),
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });

    track("billing_manage_team_user_removed", {
      removed_user: userSubscription?.invite_users?.find(u => email === u.user?.email)?.user?.email,
    });
  };

  const updateUserSeats = async () => {
    setUpdateSeatsLoading(true);
    const totalUserLicenses =
      showEditSeatsPanel === "add"
        ? (subscriptionDetails?.max_users ?? 0) + additionalSeats
        : showEditSeatsPanel === "remove"
        ? (subscriptionDetails?.max_users ?? 0) - additionalSeats
        : subscriptionDetails?.max_users ?? 0;

    await updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "modify_subscription",
      total_user_licenses: totalUserLicenses,
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });
    setShowEditSeatsPanel(null);
    setShowAddUserConfirm(false);
    setUpdateSeatsLoading(false);
    track(
      showEditSeatsPanel === "add"
        ? "billing_manage_team_licenses_added"
        : "billing_manage_team_licenses_removed",
      {
        selected_num_users: totalUserLicenses,
        [showEditSeatsPanel === "add" ? "num_licenses_added" : "num_licenses_removed"]:
          additionalSeats,
      }
    );
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);
    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      message.success("Your subscription has been updated");
      getUserSubscription();
    }
  };

  useEffect(() => {
    // set the selected billing details to populate the pricing summary
    if (subscriptionDetails?.plan_type) {
      setPlanType(subscriptionDetails?.plan_type);
    }
    if (subscriptionDetails?.billing_interval) {
      setBillingInterval(subscriptionDetails?.billing_interval);
    }
    if (subscriptionDetails?.selected_data_source_types) {
      setDataSources(subscriptionDetails?.selected_data_source_types);
    }
  }, [usersInCurrentPlan]);

  const openAddUserSeatsPopUp = () => {
    setAdditionalSeats(0);
    setShowEditSeatsPanel("add");
  };

  const openRemoveUserSeatsPopUp = () => {
    if (subscriptionDetails?.max_users) {
      setAdditionalSeats(subscriptionDetails?.max_users - usersInCurrentPlan.length);
    }
    setShowEditSeatsPanel("remove");
  };

  const subscribedAdditionalUserSeats =
    (subscriptionDetails?.max_users ?? 0) -
    (userSubscription?.invite_users ?? []).length -
    usersInCurrentPlan.length;

  // for computing new pricing summary
  const planMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "monthly"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const planAnnualMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "annually"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const numberOfUserInPlan: number =
    showEditSeatsPanel === "add"
      ? (subscriptionDetails?.max_users ?? 0) + additionalSeats
      : showEditSeatsPanel === "remove"
      ? (subscriptionDetails?.max_users ?? 0) - additionalSeats
      : subscriptionDetails?.max_users ?? 0;

  const newPriceSummary = getPricingSummary(
    subscriptionDetails?.plan_type,
    subscriptionDetails?.billing_interval ?? "annually", // billing interval
    planMonthlyPrice, // monthly price for the plan (monthly interval)
    planAnnualMonthlyPrice, // monthly price for the plan (annual interval)
    currentPlan?.per_additional_data_source_addon?.per_item_monthly_price_usd || 0, // addtional data source cost
    numberOfUserInPlan, // # of users
    subscriptionDetails?.max_data_source_types ?? 0, // # of data souce
    billingMetadata?.coupon_discount_percentage
  );
  const handleInviteEmailsChange = e => {
    // Validate emails with comma separation
    const isValid = EmailValidationRegex.test(e.target.value);
    setInviteEmails(e.target.value);
  };

  const manageLicensesMenu = (
    <Menu>
      <Menu.Item>
        <a href="javascript:void(0)" onClick={openAddUserSeatsPopUp}>
          Add Licenses
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="javascript:void(0)" onClick={openRemoveUserSeatsPopUp}>
          Remove Licenses
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Wrap>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={{ pathname: `${basePath}/subscription`, search: window.location.search }}>
            Account
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Your team</BreadcrumbItem>
      </Breadcrumb>

      <div className="mainTitle">Your Team</div>

      <InnerWrap>
        <div style={{ width: "60%" }}>
          <Section>
            <TitleSection>
              <div className="sectionTitle">
                {subscriptionDetails?.max_users} {planNameFromType(planType)} User
                {pluralize(subscriptionDetails?.max_users)}
              </div>
              <div>
                {planType === "pro" && (
                  <Dropdown
                    overlay={enableSubscriptionUpdate ? manageLicensesMenu : <></>}
                    placement="bottomLeft"
                  >
                    <DEPRECATED_Button
                      className="light"
                      style={{ marginRight: "8px" }}
                      onClick={() => {
                        if (!enableSubscriptionUpdate) {
                          setContactSupportInfoBox({ title: "Manage Licenses" });
                        }
                      }}
                    >
                      Manage Licenses
                    </DEPRECATED_Button>
                  </Dropdown>
                )}
                <DEPRECATED_Button type="primary" onClick={() => setShowInviteUser(true)}>
                  Invite User to Plan
                </DEPRECATED_Button>
              </div>
            </TitleSection>

            {usersInCurrentPlan.map((user, i) => (
              <UserList
                key={i}
                avatarColor={avatarColors[i % avatarColors.length]}
                name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                email={user.user?.email || ""}
                userId={user.user?.user_id || ""}
                plan={user.plan_type}
                footer={
                  <a
                    href="javascript:void(0)"
                    onClick={() => setDowngradeUserConfirm(user.user ?? null)}
                  >
                    Remove user from plan
                  </a>
                }
              />
            ))}

            {userSubscription?.invite_users?.map((user, i) => (
              <UserList
                key={i}
                avatarColor={avatarColors[(i + usersInCurrentPlan.length) % avatarColors.length]}
                name={
                  user.user?.first_name || user.user?.last_name
                    ? `${user.user?.first_name || ""} ${user.user?.last_name || ""}`
                    : user.user?.email ?? ""
                }
                email={user.user?.email || ""}
                userId={user.user?.user_id || ""}
                plan={user.plan_type}
                footer={
                  <a
                    href="javascript:void(0)"
                    onClick={() => setDowngradeUserConfirm(user.user ?? null)}
                  >
                    Remove user from plan
                  </a>
                }
              />
            ))}

            {subscribedAdditionalUserSeats > 0 && (
              <UserList
                className="assignUsers"
                avatarColor={COLORS.seafoam}
                avatarIcon={"+" + subscribedAdditionalUserSeats}
                name={subscribedAdditionalUserSeats + " Unassigned"}
                email={`Assign users to ${planNameFromType(
                  subscriptionDetails?.plan_type ?? "plan"
                )}`}
                userId={""}
                isSelectable={true}
                toggleUser={() => setShowInviteUser(true)}
              />
            )}
          </Section>

          {otherUsersIndomain.length > 0 && (
            <Section>
              <div className="sectionTitle">
                {usersInCurrentPlan.length > 0 ? `Other users` : "Users"}
              </div>
              {otherUsersIndomain.map((user, i) => (
                <UserList
                  key={i}
                  avatarColor={
                    avatarColors[
                      (i +
                        usersInCurrentPlan.length +
                        (userSubscription?.invite_users?.length ?? 0)) %
                        avatarColors.length
                    ]
                  }
                  name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                  email={user.user?.email || ""}
                  userId={user.user?.user_id || ""}
                  plan={user.plan_type}
                  footer={
                    <div style={{ color: COLORS.black45 }}>
                      {user.managed_by_user ? (
                        `Plan managed by ${user.managed_by_user.first_name || ""} ${
                          user.managed_by_user.last_name || ""
                        }`
                      ) : (
                        <a
                          href="javascript:void(0)"
                          onClick={() => upgradeUser(user.user?.user_id)}
                        >
                          Add user to plan
                        </a>
                      )}
                    </div>
                  }
                />
              ))}
            </Section>
          )}
        </div>

        <div style={{ width: "35%" }}>
          <FAQSection />
        </div>
      </InnerWrap>

      {/* Invite users popup */}
      <ConfirmBox
        title="Invite User To Plan"
        visible={showInviteUser || !isValidEmails}
        okText="Invite User"
        cancelText="Cancel"
        onOk={() => {
          inviteUsers();
          setShowInviteUser(!isValidEmails);
          setInviteEmails(isValidEmails ? "" : inviteEmails);
        }}
        onCancel={() => {
          setShowInviteUser(false);
          setInviteEmails("");
        }}
        width={320}
      >
        <DEPRECATED_InputTextarea
          placeholder="Enter email addresses"
          value={inviteEmails}
          onChange={handleInviteEmailsChange}
          autoSize={{ minRows: 2, maxRows: 2 }}
        />
      </ConfirmBox>

      {/* Confirm Downgrade to free popup */}
      <ConfirmBox
        title="Please confirm!"
        visible={downgradeUserConfirm !== null}
        okText={`Yes, Downgrade to Free`}
        cancelText="Cancel"
        onOk={() => {
          if (downgradeUserConfirm?.user_id) {
            downgradeUser(downgradeUserConfirm?.user_id);
          } else if (downgradeUserConfirm?.email) {
            removeInvitedUser(downgradeUserConfirm?.email);
          }
          setDowngradeUserConfirm(null);
        }}
        onCancel={() => setDowngradeUserConfirm(null)}
        width={440}
      >
        <>
          <p>
            {downgradeUserConfirm?.first_name ?? downgradeUserConfirm?.email}{" "}
            {downgradeUserConfirm?.last_name} will be removed from your {planNameFromType(planType)}{" "}
            plan and downgraded to the Free plan.
          </p>
          <p>
            Scheduled imports and exporting to data sources will no longer work and other users
            won't be able to refresh{" "}
            {downgradeUserConfirm?.first_name ?? downgradeUserConfirm?.email}'s imports
          </p>
        </>
      </ConfirmBox>

      {/* Add/Remove user seats popup */}
      <ConfirmBox
        title={`${showEditSeatsPanel === "add" ? "Add additional" : "Remove"} user licenses`}
        visible={showEditSeatsPanel !== null && !showAddUserConfirm}
        okText={
          showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0 ? "Got it" : "Next"
        }
        onOk={() => {
          if (additionalSeats > 0) {
            setShowAddUserConfirm(true);
          }
          if (showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0) {
            setShowEditSeatsPanel(null);
          }
        }}
        width={440}
        onCancel={() => setShowEditSeatsPanel(null)}
        maskClosable={true}
      >
        <>
          {showEditSeatsPanel === "add" && (
            <p>You can assign licenses to users once you purchase.</p>
          )}
          {showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats > 0 && (
            <p>
              You have {subscribedAdditionalUserSeats} unassigned license
              {pluralize(subscribedAdditionalUserSeats)} on your plan that you can remove.
            </p>
          )}
          {showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0 && (
            <p>
              You can only remove unassigned licenses from your plan. All licenses are currently
              assigned. Please unassign a license first.
            </p>
          )}
          {((showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats > 0) ||
            showEditSeatsPanel === "add") && (
            <AddSeats
              onAddUser={() =>
                showEditSeatsPanel === "remove"
                  ? setAdditionalSeats(
                      additionalSeats < subscribedAdditionalUserSeats
                        ? additionalSeats + 1
                        : additionalSeats
                    )
                  : setAdditionalSeats(additionalSeats + 1)
              }
              onRemoveUser={() => {
                if (additionalSeats > 0) {
                  setAdditionalSeats(additionalSeats - 1);
                }
              }}
              userCount={additionalSeats}
            />
          )}
          <div style={{ height: "50px" }}></div>
          {showEditSeatsPanel === "remove" && (
            <HelpSubscription>
              <p
                style={{
                  fontSize: "14px",
                  color: COLORS.black65,
                  background: COLORS.yellowBg2,
                  padding: "8px 8px 8px 8px",
                }}
              >
                Removing user licenses does NOT cancel your subscription with Coefficient,{" "}
                <a
                  href="mailto:support@coefficient.io"
                  className="underline"
                  style={{ color: "inherit" }}
                >
                  contact support to cancel.
                </a>
              </p>
              <div style={{ height: "20px" }}></div>
            </HelpSubscription>
          )}
        </>
      </ConfirmBox>

      {/* Confirm Add/Remove user seats popup */}
      <ConfirmBox
        title={`${showEditSeatsPanel === "add" ? "Add additional" : "Remove"} user licenses`}
        visible={showAddUserConfirm}
        okText={`Confirm Change`}
        cancelText={`Cancel`}
        onOk={() => updateUserSeats()}
        onCancel={() => {
          setShowAddUserConfirm(false);
          setShowEditSeatsPanel(null);
        }}
        width={440}
        confirmLoading={updateSeatsLoading}
      >
        <>
          <p>
            This is your plan summary after {showEditSeatsPanel === "add" ? "adding" : "removing"}{" "}
            {additionalSeats} license
            {pluralize(additionalSeats)}.
          </p>
          <PricingSummaryWrap>
            <h3>{planNameFromType(planType)} Plan (after changes)</h3>
            <PriceSummaryProrated
              editType={showEditSeatsPanel === "add" ? "add-seats" : "remove-seats"}
              newPriceSummary={newPriceSummary}
            />
          </PricingSummaryWrap>
        </>
      </ConfirmBox>
    </Wrap>
  );
};
