import {
  DataSourceType,
  DueInvoiceErrorType,
  FeatureFlag,
  GetSubscriptionInfo200Response,
  SubscriptionBillingInterval,
  SubscriptionInvoice,
  SubscriptionPlanType,
  SubscriptionUser,
  UserSubscriptionInfo,
} from "@coeff/api";
import * as Sentry from "@sentry/browser";
import querystring from "query-string";
import React, { ReactNode, useEffect, useState } from "react";
import { MapDispatchToProps, MapStateToProps, connect } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { AppState } from "src/store";
import { useGate } from "statsig-react";
import styled from "styled-components";

import {
  AppThunkDispatch,
  checkout,
  getInvoices,
  getPreCheckoutData,
  getUserSubscription,
  updateSubscription,
} from "../../actions";
import { Alert, CoeLogo, ConfirmBox, LoaderWithPerfTimings, Typography } from "../../components";
import { BContext, BillingPage, BillingPreCheckoutData } from "../../types";
import { getPricingSummary, useTrack } from "../../utils";

import { BillingHistory } from "./pages/BillingHistory";
import { ManageTeam } from "./pages/ManageTeam";
import { Payment } from "./pages/Payment";
import { PlanCustomizer } from "./pages/PlanCustomizer";
import { PlanSelector } from "./pages/PlanSelector";
import { SubscriptionInfo } from "./pages/SubscriptionInfo";
import { Team } from "./pages/Team";
import { UpdatePayment } from "./pages/UpdatePayment";
import { ManageTeamV2 } from "./pagesV2/ManageTeam";
import { PlanCustomizerV2 } from "./pagesV2/PlanCustomizer";
import { PlanSelectorV2 } from "./pagesV2/PlanSelector";
import { SubscriptionInfoV2 } from "./pagesV2/SubscriptionInfo";
import { TeamV2 } from "./pagesV2/Team";

const Wrap = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 1rem;

  &.iframe {
    padding: 0px;
  }

  &.excel {
    padding: 24px 16px 16px 16px;
  }

  &.dashboard {
    margin: 0px;
    padding: 20px 30px;
  }
`;

const CoeLogoSmall = styled(CoeLogo)`
  width: 120px;
  margin: 10px 0 18px 0;
`;

const isExcel = querystring.parse(window.location.search).platform === "excel_addon";

const isIFrame = querystring.parse(window.location.search).isiframe === "true";

const userEmail = querystring.parse(window.location.search).email;

export const BillingContext = React.createContext<BContext | null>(null);

type ConnectDispatchProps = {
  checkout: (body: any) => Promise<unknown>;
  getPreCheckoutData: () => Promise<unknown>;
  getUserSubscription: () => Promise<GetSubscriptionInfo200Response>;
  getInvoices: () => void;
  updateSubscription: (body: any) => Promise<unknown>;
};

type ConnectProps = {
  coeffUserEmail: string;
  metadata: BillingPreCheckoutData;
  userSubscription?: GetSubscriptionInfo200Response | null;
  free_trial_expiration_dt?: Date;
  free_trial_total_days?: number;
  plan_type?: SubscriptionPlanType;
  due_invoices_count?: number;
  due_invoice_error_type?: DueInvoiceErrorType;
  invoices: Array<SubscriptionInvoice>;
  upgradeToken?: string;
  domains_in_domain_family: string[];
};

type Props = ConnectProps & ConnectDispatchProps;

const Billing: React.FC<Props> = ({
  checkout,
  coeffUserEmail,
  getPreCheckoutData,
  getUserSubscription,
  getInvoices,
  metadata: billingMetadata,
  userSubscription,
  updateSubscription,
  free_trial_expiration_dt,
  free_trial_total_days,
  plan_type,
  due_invoices_count,
  due_invoice_error_type,
  invoices,
  upgradeToken,
  domains_in_domain_family,
}) => {
  const track = useTrack();

  const { value: enableGmailDiscountTake50Coeff } = useGate(
    FeatureFlag.EnableGmailDiscountTake50coeff
  );

  const { value: enablePlanBillingV2 } = useGate(FeatureFlag.EnablePlanBillingV2);

  const [page, setPage] = useState<BillingPage>(
    userSubscription ? "subscription-info" : "plan-selector"
  );

  const [loading, setLoading] = useState<boolean>(true);

  const [selectedPlanType, setPlanType] = useState<SubscriptionPlanType | undefined>();

  const [selectedBillingInterval, setBillingInterval] =
    useState<SubscriptionBillingInterval>("annually");

  const [selectedDataSources, setDataSources] = useState<DataSourceType[]>([]);

  const [selectedUsers, setUsers] = useState<SubscriptionUser[]>([]);

  const [invitedUserEmails, setInvitedUserEmails] = useState<string[]>([]);

  const [checkoutSuccess, setCheckoutSuccess] = useState<boolean>(false);

  const [additionalSeats, setAdditionalSeats] = useState<number>(0);

  const [contactSupportInfoBox, setContactSupportInfoBox] = useState<{
    title: string;
    message?: ReactNode;
  }>();

  const currentUserEmail = coeffUserEmail ?? userEmail;
  const domain = currentUserEmail ? currentUserEmail.split("@").pop() : undefined;

  const enableSubscriptionUpdate: boolean =
    userSubscription?.subscription_details?.checkout_mode !== "contact_us";

  const history = useHistory();

  const location = useLocation();

  const { path } = useRouteMatch();

  const searchParams = new URLSearchParams(location.search);

  const upgradeToPro = searchParams.get("upgrade_to_pro");

  useEffect(() => {
    const init = async () => {
      await getPreCheckoutData();

      const userSubscription = await getUserSubscription();

      setLoading(false);

      setBillingInterval(userSubscription.subscription_details?.billing_interval ?? "annually");

      const eventFrom = searchParams.get("from");
      if (!upgradeToPro) {
        track("billing_plan_chooser_viewed", { event_from: eventFrom ?? undefined });
      }

      window.opener?.postMessage(JSON.stringify({ type: "billing:loaded" }), "*");
    };

    init();

    document.title = "Plans & Billing - Coefficient Workspace";
  }, []);

  useEffect(() => {
    if (!loading && !billingMetadata.plans.length) {
      Sentry.captureException(new Error("Session expired displayed"));
    }
  }, [loading, billingMetadata]);

  useEffect(() => {
    if (upgradeToPro) {
      setPlanType("pro");
      track("billing_plan_selected", {
        event_from: "email_import_refresh_blocked",
        selected_frequency: selectedBillingInterval,
        selected_plan_type: "pro",
      });
      history.push({
        pathname: `${path}/customize`,
        search: window.location.search,
      });
    } else if (userSubscription && location.pathname === `${path}/plans`) {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.get("from") === "billing" && enablePlanBillingV2) {
        return;
      }
      history.push({
        pathname: `${path}/subscription`,
        search: window.location.search,
      });
    } else if (userSubscription === null) {
      history.push({
        pathname: `${path}/plans`,
        search: window.location.search,
      });
    }
  }, [userSubscription]);

  const planData = billingMetadata?.plans.find(
    p => p.plan_type === selectedPlanType && p.billing_interval === selectedBillingInterval
  );

  const planMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "monthly"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const planAnnualMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "annually"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const priceSummary = getPricingSummary(
    planData?.plan_type, // plan type
    selectedBillingInterval, // billing interval
    planMonthlyPrice, // monthly price for the plan (monthly interval)
    planAnnualMonthlyPrice, // monthly price for the plan (annual interval)
    planData?.per_additional_data_source_addon?.per_item_monthly_price_usd || 0, // addtional data source cost
    selectedUsers.length + additionalSeats + invitedUserEmails.length, // # of users
    selectedDataSources.length, // # of data souce
    // for visual purposes only, we add a 50 percent discount for gmail users when viewing the price summary
    // this DOES NOT affect the actual price calculation during checkout
    enableGmailDiscountTake50Coeff && domain === "gmail.com"
      ? 50
      : billingMetadata?.coupon_discount_percentage
  );

  // Subscription information
  const usersInCurrentPlan: UserSubscriptionInfo[] = [];
  const otherUsersIndomain: UserSubscriptionInfo[] = [];
  let currentUserPlanManager: SubscriptionUser | undefined;
  userSubscription?.users_from_domain.forEach(user => {
    if (user.managed_by_user?.email === currentUserEmail) {
      usersInCurrentPlan.push(user);
    } else {
      otherUsersIndomain.push(user);
    }
    if (
      (user.user?.email === currentUserEmail && user.plan_type) ||
      user.managed_by_user?.email === currentUserEmail
    ) {
      currentUserPlanManager = user.managed_by_user;
    }
  });

  if (currentUserPlanManager === undefined) {
    currentUserPlanManager = otherUsersIndomain.find(
      u => u.user.email === userSubscription?.subscription_details?.subscription_owner_email
    )?.user;
  }

  otherUsersIndomain.sort((a, b) => {
    return (b.num_imports || 0) - (a.num_imports || 0);
  });

  const basePath = path;

  return (
    <BillingContext.Provider
      value={{
        additionalSeats,
        basePath,
        billingMetadata,
        checkout,
        checkoutSuccess,
        currentUserEmail,
        enableSubscriptionUpdate,
        domain,
        free_trial_expiration_dt,
        free_trial_total_days,
        getUserSubscription,
        getInvoices,
        page,
        plan_type,
        priceSummary,
        selectedPlanType,
        selectedBillingInterval,
        selectedDataSources,
        selectedUsers,
        setCheckoutSuccess,
        setContactSupportInfoBox,
        setPage,
        setPlanType,
        setBillingInterval,
        setDataSources,
        setAdditionalSeats,
        setUsers,
        // @ts-ignore - doesn't account for error object coming from reducer action
        updateSubscription,
        userSubscription,
        due_invoices_count,
        due_invoice_error_type,
        usersInCurrentPlan,
        otherUsersIndomain,
        currentUserPlanManager,
        invoices,
        upgradeToken,
        domains_in_domain_family,
        invitedUserEmails,
        setInvitedUserEmails,
      }}
    >
      {loading ? (
        <div style={{ margin: "7rem auto 2rem", textAlign: "center" }}>
          <LoaderWithPerfTimings name="Billing" size="large" />
        </div>
      ) : billingMetadata.plans.length > 0 ? (
        <>
          <Wrap
            className={`${isIFrame ? "iframe" : ""} ${isExcel ? "excel" : ""} ${
              location.pathname.includes("dashboard") ? "dashboard" : ""
            }`}
          >
            {!isIFrame &&
              !location.pathname.includes("dashboard") &&
              location.pathname !== `${path}/update-payment` && <CoeLogoSmall />}
            <Switch>
              <Route exact path={`${path}`}>
                <Redirect
                  to={{
                    pathname: `${path}/plans`,
                    search: window.location.search,
                  }}
                />
              </Route>
              <Route path={`${path}/plans`}>
                {enablePlanBillingV2 ? <PlanSelectorV2 /> : <PlanSelector />}
              </Route>
              <Route path={`${path}/customize`}>
                {enablePlanBillingV2 ? <PlanCustomizerV2 /> : <PlanCustomizer />}
              </Route>
              <Route path={`${path}/payment`}>
                <Payment />
              </Route>
              <Route path={`${path}/subscription`}>
                {enablePlanBillingV2 ? <SubscriptionInfoV2 /> : <SubscriptionInfo />}
              </Route>
              <Route path={`${path}/billing`}>
                <BillingHistory />
              </Route>
              <Route path={`${path}/team/manage`}>
                {enablePlanBillingV2 ? <ManageTeamV2 /> : <ManageTeam />}
              </Route>
              <Route path={`${path}/team`}>{enablePlanBillingV2 ? <TeamV2 /> : <Team />}</Route>
              <Route path={`${path}/update-payment`}>
                <UpdatePayment />
              </Route>
            </Switch>
          </Wrap>

          <ConfirmBox
            title={contactSupportInfoBox?.title}
            visible={Boolean(contactSupportInfoBox)}
            onOk={() => setContactSupportInfoBox(undefined)}
            okText="Got it"
            width={400}
          >
            {contactSupportInfoBox?.message ? (
              contactSupportInfoBox.message
            ) : (
              <>
                Please contact us at{" "}
                <a href="mailto:support@coefficient.io" target="_blank">
                  support@coefficient.io
                </a>
                <br />
                to make changes
              </>
            )}
          </ConfirmBox>
        </>
      ) : (
        <div>
          <Alert
            message={
              <Typography variant="body1" fontWeight="bold">
                Session expired
              </Typography>
            }
            description={
              <a href="/dashboard/billing" target="_blank" style={{ fontWeight: "bold" }}>
                Back to Plans & Billing
              </a>
            }
            type="error"
            style={{ margin: "1rem", padding: 16 }}
          />
        </div>
      )}
    </BillingContext.Provider>
  );
};

const mapDispatchToProps: MapDispatchToProps<ConnectDispatchProps, unknown> = (
  dispatch: AppThunkDispatch
) => ({
  getPreCheckoutData: () => dispatch(getPreCheckoutData()),
  getUserSubscription: () => dispatch(getUserSubscription()),
  getInvoices: () => dispatch(getInvoices()),
  updateSubscription: body => dispatch(updateSubscription(body)),
  checkout: body => dispatch(checkout(body)),
});

const mapStateToProps: MapStateToProps<ConnectProps, unknown, AppState> = ({
  billing: { metadata, userSubscription, invoices },
  app: {
    coeffUserEmail,
    free_trial_expiration_dt,
    free_trial_total_days,
    plan_type,
    due_invoices_count,
    due_invoice_error_type,
    upgradeToken,
    domains_in_domain_family,
  },
}) => ({
  metadata,
  userSubscription,
  coeffUserEmail,
  free_trial_expiration_dt,
  free_trial_total_days,
  plan_type,
  due_invoices_count,
  due_invoice_error_type,
  invoices,
  upgradeToken,
  domains_in_domain_family,
});

const route = withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing));

export { route as Billing };
